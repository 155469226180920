import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import "./MainDash.css";
import profilePicture from "../../images/profilePicture.png";
import homeIcon from "../../images/homeIcon.png";
import projectsIcon from "../../images/projectsIcon.png";
import paymentIcon from "../../images/paymentIcon.png";
import settingsIcon from "../../images/settingsIcon.png";
import SideMenu from "../Basic/SideMenu/SideMenu";
import ProjectsContext from "../../contexts/ProjectsContext";
import Lottie from "lottie-react";
import emptyBox from "./empty_box.json";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { UserAuth } from "../../contexts/AuthContext";
import { Box, Button, Typography } from "@mui/material";
import LoadingOverlay from "react-loading-overlay";
import { BounceLoader } from "react-spinners";

const columns = [
  { id: "name", label: "Project Name", minWidth: 170 },
  { id: "date", label: "Date", minWidth: 100 },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
  },
  {
    id: "client",
    label: "Client",
    minWidth: 170,
  },
];

function createData(name, date, status, client, id) {
  return { name, date, status, client, id };
}

const MainDash = () => {
  const {
    projectList,
    loading,
    getProjects,
    getUserProjects,
    projectListByUid,
  } = useContext(ProjectsContext);

  
  const navigate = useNavigate();
  // useEffect(() => {
  //   getProjects()
  //   getUserProjects(user.uid)
  // }, [user])

  //console.log('daynamic Data', projectList)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = projectList.map((project) =>
    createData(
      project.title,
      project.time,
      project.complete ? "Completed" : project.status,
      project.author.name,
      project.id
    )
  );
  return (
    <LoadingOverlay active={loading} spinner={<BounceLoader color="white" />}>
      <div className="main-profile-container">
        <SideMenu />
        {/* <div className="projects-list">
        <div className="active-projects-heading">Active Projects</div>
        <table>
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {isAdmin ?
              ( projectList.map((project) => (
                  <tr>
                    <td >

                        <Link  className="project-name"  to={`./project/${project.id}`}>
                          {project.title}
                        </Link>
                    </td>
                    <td>{project.time}</td>
                    <td>{project.status}</td>
                  </tr>
                )))
                
      :
                   (  projectList
                      .filter((project) => project.author?.id === auth.currentUser.uid)
                      .map((project) => (
                       <tr className="project-item-container" key={project.id}>
                         <td>
                           <div  className="project-name">
                           <Link   to={`./project/${project.id}`}>
                             {project.title}
                           </Link>
                           </div>
                         </td>
                         <td>{project.time}</td>
                         <td>{project.status}</td>
                       </tr>
                     )))
                
                }
 
      
                  
          </tbody>
        </table>
      </div>  */}

        {!(projectList.length > 0) && !loading ? (
          <Box
            sx={{
              width: "100%",
              mt: 10,
              height: "550px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: "25px" }}>
              You don't have any Projects yet!
            </Typography>
            <Box sx={{ width: "20%" }}>
              <Lottie animationData={emptyBox} />
            </Box>

            <div class="text-center min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
              <Link to="/postproject">
              <Button type='submit' variant="contained">Submit your First Project</Button>
              </Link>
            </div>
          </Box>
        ) : (
          <Paper sx={{ width: "100%", mt: 10, height: "550px" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 0, minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          sx={{ width: "100px" }}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];

                            return (
                              <TableCell key={column.id} align={column.align}>
                                {/* {row.id} */}
                                <Link to={`./project/${row.id}`}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </Link>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default MainDash;
