


import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { toast } from "react-toastify";
import { db } from "../firebase-config";

export const INITIAL_STATE = Object.freeze({
  projects: [],
});
const usePostProjectToPlatform = (project) => {
  const [error, setError] = useState(null);
  const handleError = useCallback(
    (e) => {
      setError(e);
    },
    [setError]
  );
  const[loading,setLoading]=useState(true)

  const params = JSON.stringify({
    "title": project.title,
    "description": project.description,
    "currency": {
      "id": project.currencyId
    },
    "budget": {
      "minimum": parseInt(project.minBudget),
      "maximum": parseInt(project.maxBudget)
    },
    "jobs": [
      {
        "id": project.skillId
      }
    ]
  }
  );

  useEffect(() => {
    const postProject = () => {
      try {
        axios.post("https://www.freelancer-sandbox.com/api/projects/0.1/projects/", params, {
          "headers": {
            "content-type": "application/json",
            "Freelancer-OAuth-V1": "ITcCI8XEJqT7xdTbNCI2FsFWc1BTa0",
          }
        })
          .then(
            setLoading(false)
          ).then(
            toast.success('The project is Submitted successfully', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            })
          )
    
      } catch (error) {
        //console.log(error)
        setLoading(false)
        toast.error('Error Occured , Please try again', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
    
  
    
    };
    
    postProject()
  },[]);

  return {
    loading
  };
};

export default usePostProjectToPlatform;
