import React, { useContext } from 'react'
import Carousel from '../components/Carousel/Carousel'
import { Content } from '../components/Content/Content'
import Features from '../components/Features/Features'
import Hero from '../components/Hero/Hero'
import Panel from '../components/Panel/Panel'
import Vision from '../components/Vision/Vision'
import ProjectsContext from '../contexts/ProjectsContext'
import { heroOne, heroTwo, heroThree } from '../data/HeroData'
//Hero features content -carousel
const Home = () => {

  return (
    <>
      <Hero />
      <Features />
      <Panel />
      <Vision />
      {/* <Carousel/> */}

      {/* <Content {...heroOne}  />
          <Content {...heroTwo}  />
          <Content {...heroThree}  /> */}

    </>
  )
}

export default Home