import React from "react";
import "./Panel.css";
import { Container, Section } from "../../globalStyles";
import { Slide } from "react-awesome-reveal";

const Panel = () => {
  return (
    <Section smPadding="50px 10px" position="relative" inverse id="experts">
      <div className="panel-container">
        <div className="panel-wrapper">
          <Slide direction="left">
            <div className="panel-text">
              <div className="panel-main-text">
                Our team has a global footprint, driven by bridging talent and
                innovation opportunities{" "}
              </div>
              <button>Get Started </button>
            </div>
          </Slide>
          <Slide direction="right">
            <img
              className="freelancer-image"
              src="./assets/man.jpg"
              alt="freelancer-image"
            />
          </Slide>
        </div>
      </div>
    </Section>
  );
};

export default Panel;
