import React from 'react';
import { icons } from '../../utils/icons';
import './Footer.css'
import { useLocation, useHistory, useNavigate } from "react-router-dom";

function Footer() {

	let navigate = useNavigate();
	let location = useLocation();

	const closeMobileMenu = (to, id) => {
		if (id && location.pathname === "/") {
			scrollTo(id);
		}

		navigate(to);
	};

	const scrollTo = (id) => {
		const element = document.getElementById(id);
	
		element.scrollIntoView({
		  behavior: "smooth",
		});
	  };
	
	return (
		<>
			<div className='footer-container'>
				<div className='footer-wrapper'>
					<div className='col'>
						<div className='col-heading' onClick={() => closeMobileMenu("/")}>
							<img className='logo' src={icons.Logo} alt='logo'  />
						</div>
					</div>
					<div className='col' onClick={() => closeMobileMenu("/", "about")}>
						About
					</div>
					<div className='col' onClick={() => closeMobileMenu("/", "how")}>
						How it works
					</div>
					<div className='col' onClick={() => closeMobileMenu("/", "experts")}>
						Our Experts
					</div>
					<div className='col' onClick={() => closeMobileMenu("/postProject")}>
						Post Project
					</div>
					<div className='col'>
						Contact us
					</div>
				</div>
			</div>
			<footer class="text-gray-600 body-font">
				<div class="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">

					<p class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2023 Beskope —All rights reserved
					</p>
					<span class="inline-flex sm:ml-auto sm:mt-0  justify-center sm:justify-start">
						<a class="text-gray-500">
							<svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
								<path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
							</svg>
						</a>
						<a class="ml-3 text-gray-500">
							<svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
								<path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
							</svg>
						</a>
						<a class="ml-3 text-gray-500">
							<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
								<rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
								<path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
							</svg>
						</a>
						<a class="ml-3 text-gray-500">
							<svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
								<path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
								<circle cx="4" cy="4" r="2" stroke="none"></circle>
							</svg>
						</a>
					</span>
				</div>
			</footer>
		</>

	);
}

export default Footer;
