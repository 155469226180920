import { Box } from '@mui/material'
import React from 'react'
import SubmitPlatformProject from '../../components/Basic/SubmitPlatformProject/SubmitPlatformProject'

const PlatformInterface = () => {
  return (
    <Box >
    <SubmitPlatformProject/>
    </Box>
  )
}

export default PlatformInterface