import React from "react";
import { Slide } from "react-awesome-reveal";
import { Section } from "../../globalStyles";
import { FeatureTextWrapper, FeatureTitle } from "../Features/FeaturesStyles";
import "./Vision.css";

const Vision = () => {
  return (
    <Section style={{ padding: "0px" }} inverse id="about">
      <div className="vision-container">
        <FeatureTextWrapper>
          <FeatureTitle>Why Us</FeatureTitle>
        </FeatureTextWrapper>

        <div className="vision-wrapper">
          <Slide direction="up">

            <div className="vision-item">
              <div className="vision-item-heading">
                We are <span>innovators</span>.
              </div>
              <div className="vision-item-desc">
                We <span>embrace uncertainty</span> and think{" "}
                <span>beyond the horizon</span>, finding{" "}
                <span>creative solutions</span> to the opportunities around us.
              </div>
            </div>
            <div className="vision-item">
              <div className="vision-item-heading">
                We are <span>connectors</span>.
              </div>
              <div className="vision-item-desc">
                We <span>understand</span> your needs and get you the <span>right solutions</span>, right away
              </div>
            </div>
            <div className="vision-item">
              <div className="vision-item-heading">
                We are <span>human</span>.
              </div>
              <div className="vision-item-desc">
              All <span>needs</span>  are different. We tailor <span>the journey </span>to help you navigate through <span>the chaos</span> of building your product.              </div>
            </div>
          </Slide>

        </div>

      </div>
    </Section>
  );
};

export default Vision;
