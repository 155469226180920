import { useContext, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

// import CardIcon from "../images/credit-card.svg";
// import ProductImage from "../images/product-image.jpg";
import profilePicture from "../../images/profilePicture.png";
import homeIcon from "../../images/homeIcon.png";
import projectsIcon from "../../images/projectsIcon.png";
import paymentIcon from "../../images/paymentIcon.png";
import settingsIcon from "../../images/settingsIcon.png";
import creditCard from "../../images/creditCard.png";
import stripePayment from "../../images/stripePayment.png";

import "./Checkout.css";
import { Link, useNavigate } from "react-router-dom";
import { useElements } from "@stripe/react-stripe-js";
import SideMenu from "../Basic/SideMenu/SideMenu";
import ProjectsContext from "../../contexts/ProjectsContext";
import GooglePayButton from '@google-pay/button-react';
import { Box, Button } from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-config";


let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_test_51Lbnr1Bc14HyDrRlN0w7kFyVOOeMr9723EcGCUULpiptjub2YBuqYsRhOOfRd9plEyRAveQjuvUMTaSkXGZoP4jL00WiZxJZQX"
    );
  }

  return stripePromise;
};

const Checkout = ({ isAdmin }) => {
  const { projectList, loading } = useContext(ProjectsContext);

  const projectId = window.location.pathname.replace("/profile/project/", "");
  const projectIdd = projectId.replace("/payment", "");
  const proj = projectList.filter((project) => project.id === projectIdd);
  //console.log(proj)
  const docRef = doc(db, "Projects", projectIdd);

  const [title, setTitle] = useState(proj[0].title);
  const [deliverables, setDeliverables] = useState(proj[0].deliverables);
  const status = "Approved";
  const navigate = useNavigate()

  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const item = {
    price: "price_1LbnwKBc14HyDrRlXppWSn1T",
    quantity: 1,
  };

  const checkoutOptions = {
    lineItems: [item],
    mode: "payment",
    successUrl: `${window.location.origin}/profile/project/${projectIdd}/payment/success`,
    cancelUrl: `${window.location.origin}/cancel`,
  };

  const redirectToCheckout = async () => {
    setLoading(true);
    //console.log("redirectToCheckout");

    const stripe = await getStripe();




    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    //console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoading(false);

  };

  if (stripeError) alert(stripeError);




  const updateDepositePayment = async () => {
    await updateDoc(docRef, {

      deposite: true,

    });
  };
  const updateFullPayment = async () => {
    await updateDoc(docRef, {

      fullPayment: true,

    });
  };



  const isDepositePaid = proj[0].deposite;
  const isFullAmountPaid = proj[0].fullPayment;
  return (
    <Box sx={{ overflow: "auto", height: 250, mb: 2 }}>
      <div className="checkout ">
        <div className="deposite-container flex flex-col lg:flex-row ">
          <p className="mb-5 lg:mb-0"> {`Pay a Deposit (10%) : ${(proj[0].budget) * 0.1} ${proj[0].currency}`}</p>
          {isDepositePaid ? <Button  sx={{ backgroundColor: 'greenyellow' }}>Paid</Button> : !isAdmin ? <GooglePayButton
            environment="TEST"
            paymentRequest={{
              apiVersion: 2,
              apiVersionMinor: 0,
              allowedPaymentMethods: [
                {
                  type: 'CARD',
                  parameters: {
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                    allowedCardNetworks: ['MASTERCARD', 'VISA'],
                  },
                  tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                      gateway: 'example',
                      gatewayMerchantId: 'exampleGatewayMerchantId',
                    },
                  },
                },
              ],
              merchantInfo: {
                merchantId: '12345678901234567890',
                merchantName: 'Demo Merchant',
              },
              transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: '1',
                currencyCode: proj[0].currency,
                countryCode: 'US',
              },
              shippingAddressRequired: true,
              callbackIntents: ['SHIPPING_ADDRESS', 'PAYMENT_AUTHORIZATION'],
            }}
            onLoadPaymentData={paymentRequest => {
              //console.log('Success', paymentRequest);
            }}
            onPaymentAuthorized={paymentData => {
              //console.log('Payment Authorised Success', paymentData)
              updateDepositePayment()
              navigate(`/profile/project/${projectIdd}/payment/success`)
              return { transactionState: 'SUCCESS' }
            }
            }
            onPaymentDataChanged={paymentData => {
              //console.log('On Payment Data Changed', paymentData)
              return {}
            }
            }
            existingPaymentMethodRequired='false'
            buttonColor='black'
            buttonType='Buy'
          /> : <Button sx={{ backgroundColor: 'orange' }}>Not Paid</Button>}
        </div>
        <div className="fullamount-container flex flex-col lg:flex-row">
          <p className="mb-5 lg:mb-0">
            {isDepositePaid ? `Pay The Remaining Amount : ${(proj[0].budget) - ((proj[0].budget) * 0.1)} ${proj[0].currency}` : `Pay Full Amount : ${(proj[0].budget)} ${proj[0].currency}`}

          </p>

          {isFullAmountPaid ? <Button sx={{ backgroundColor: 'greenyellow' }}>Paid</Button> : !isAdmin ?
            <GooglePayButton
              environment="TEST"
              paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                  {
                    type: 'CARD',
                    parameters: {
                      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                      allowedCardNetworks: ['MASTERCARD', 'VISA'],
                    },
                    tokenizationSpecification: {
                      type: 'PAYMENT_GATEWAY',
                      parameters: {
                        gateway: 'example',
                        gatewayMerchantId: 'exampleGatewayMerchantId',
                      },
                    },
                  },
                ],
                merchantInfo: {
                  merchantId: '12345678901234567890',
                  merchantName: 'Demo Merchant',
                },
                transactionInfo: {
                  totalPriceStatus: 'FINAL',
                  totalPriceLabel: 'Total',
                  totalPrice: '1',
                  currencyCode: proj[0].currency,
                  countryCode: 'US',
                },
                shippingAddressRequired: true,
                callbackIntents: ['SHIPPING_ADDRESS', 'PAYMENT_AUTHORIZATION'],
              }}
              onLoadPaymentData={paymentRequest => {
                //console.log('Success', paymentRequest);
                updateFullPayment()
                navigate(`/profile/project/${projectIdd}/payment/success`)

              }}
              onPaymentAuthorized={paymentData => {
                //console.log('Payment Authorised Success', paymentData)
                navigate(`/profile/project/${projectIdd}/payment/success`)

                return { transactionState: 'SUCCESS' }
              }
              }
              onPaymentDataChanged={paymentData => {
                //console.log('On Payment Data Changed', paymentData)
                return {}
              }
              }
              existingPaymentMethodRequired='false'
              buttonColor='black'
              buttonType='Buy'
            /> : <Button sx={{ backgroundColor: 'orange' }}>Not Paid</Button>}

        </div>
        <div className="payment-methods-container ">
          <profilePicture>We accept : </profilePicture>
          <img className="payment-methods" src={stripePayment} alt='' />

        </div>
      </div>
    </Box>
  );
};

export default Checkout;
