import React from 'react';

import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { AiOutlineFileDone } from 'react-icons/ai';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BiSupport, BiDollar } from 'react-icons/bi';
import { AiFillSafetyCertificate } from 'react-icons/ai';

const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const featuresData = [
	{
		name: 'Post Project',
		description: 'Uploading your brief takes seconds, literally.',
		icon: iconStyle(AiOutlineFundProjectionScreen),
		imgClass: 'one',
	},
	{
		name: 'Get Job Done',
		description: 'We have access to 70M+ talents globally, at least one of them has the answer you’re after',
		icon: iconStyle(AiOutlineFileDone),
		imgClass: 'two',
	},
	{
		name: 'Peace of Mind',
		description: 'You own the IP and everything that is produced. No ifs or buts.',
		icon: iconStyle(AiFillSafetyCertificate),
		imgClass: 'three',
	},
	// {
	// 	name: '24/7 Support',
	// 	description: 'Our team is available at all times in case you need us',
	// 	icon: iconStyle(BiSupport),
	// 	imgClass: 'four',
	// },
	// {
	// 	name: 'Price',
	// 	description: 'We offer the highest value/cost ratio',
	// 	icon: iconStyle(BiDollar),
	// 	imgClass: 'five',
	// },
	// {
	// 	name: 'Scalable',
	// 	description:
	// 		'Our servers are located all over the world, therefore improving scalability and speed ',
	// 	icon: iconStyle(AiOutlineCloudUpload),
	// 	imgClass: 'six',
	// },
];
