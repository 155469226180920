import React, { useEffect } from "react";
import GlobalStyle from "./globalStyles.js";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";
import './index.css'

import Home from "./pages/Home";
import { auth } from "./firebase-config";

import PostProject from "./pages/PostProject";
import PricingPage from "./pages/PricingPage";
import Login from "./pages/Login";
import Navbar from "./components/Navbar/Navbar.js";
import Footer from "./components/Footer/Footer.js";
import { useState } from "react";
import Profile from "./pages/Profile";
import ProjectPage from "./components/ProjectPage/ProjectPage.js";
import MainDash from "./components/MainDash/MainDash.js";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase-config.js";
import Form from "./components/Form/Form.js";
import Files from "./components/Files/Files.js";
import Checkout from "./components/Checkout/Checkout.js";
import Success from "./components/Checkout/Success.js";
import PlatformInterface from "./components/PlatformInterface/PlatformInterface.js";
import {useProjects} from "./hooks/useProjects.jsx";
import { ProjectsContextProvider } from "./contexts/ProjectsContext.js";
import DevelopmentHeader from "./components/Basic/DevelopmentHeader/DevelopmentHeader.js";
import { AuthContextProvider } from "./contexts/AuthContext.js";
import Protected from "./components/Protected.js";


export const UserContext = React.createContext();


function App() {
  const userEmail = auth.currentUser?.email;
  const isAdmin = userEmail === "rizkgendy@gmail.com" ? true : false;
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
  const [projectList, setProjectList] = useState([]);
  const projectsCollectionRef = collection(db, "Projects");

  // useEffect(() => {
  //   const getProjects = async () => {
  //     const data = await getDocs(projectsCollectionRef);
  //     setProjectList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   };
  //   getProjects();
  // }, [projectList]);
  useProjects()
  return (
    <Router>
      <GlobalStyle />
      <AuthContextProvider>
        {/* <DevelopmentHeader/> */}
        <Navbar isAuth={isAuth} setIsAuth={setIsAuth} />
        <ProjectsContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/postproject" element={<PostProject />} />
            <Route path="/login" element={<Login setIsAuth={setIsAuth} />} />
            <Route path="/pricing" exact element={<PricingPage />} />
            <Route path="/profile" exact element={<Protected><MainDash projectList={projectList}  /></Protected> } />
            <Route exact path="profile/project/:id" element={<Protected><ProjectPage isAdmin={isAdmin} /></Protected>} />
            <Route exact path="profile/project/:id/files" element={<Protected><Files projectList={projectList} isAdmin={isAdmin} /></Protected>} />
            <Route exact path="profile/project/:id/payment" element={<Protected><Checkout isAdmin={isAdmin} /></Protected>} />
            <Route exact path="profile/project/:id/platform" element={<Protected><PlatformInterface projectList={projectList} isAdmin={isAdmin} /></Protected>} />
            <Route exact path="profile/project/:id/payment/success" element={<Protected><Success projectList={projectList} isAdmin={isAdmin} /></Protected>} />
          </Routes>
        </ProjectsContextProvider>
        <Footer />
      </AuthContextProvider>
    </Router>
  );
}

export default App;
