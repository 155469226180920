import React from "react";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { Button, Container, MainHeading } from "../../globalStyles";
import {
  HeroVideo,
  HeroSection,
  HeroText,
  ButtonWrapper,
  HeroButton,
} from "./HeroStyles";

const Hero = () => {
  return (
    <HeroSection>
      <HeroVideo src="./assets/hero-3.mp4" autoPlay muted loop></HeroVideo>
      <Slide direction="down">
        <Container>
          <MainHeading>
            The most difficult part of any project is finding great talent.
          </MainHeading>
          <HeroText>
            We find you the best freelancers for any job, any time.
          </HeroText>
        </Container>
        <ButtonWrapper>
          <Link to="postproject">
            <Button>Post Project</Button>
          </Link>
          <HeroButton>Learn More</HeroButton>
        </ButtonWrapper>
      </Slide>
    </HeroSection>
  );
};

export default Hero;
