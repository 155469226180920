import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import{ getFirestore } from 'firebase/firestore'
import {getAuth , GoogleAuthProvider}  from 'firebase/auth'
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey:process.env.REACT_APP_FIREBASE_KEY,
  authDomain:process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket:process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId:process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
export const storage = getStorage(app)

export const db =  getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
