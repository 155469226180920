import styled from "styled-components";

export const HeaderContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-evenly;
height:30px;
background-color:  #2e51ed;
color:white;


@media (max-width: 900px) {
  }

`;
export const CountdownContainer = styled.div`
display: flex;
align-items: center;



@media (max-width: 900px) {
  }

`;
export const DevelopmentText = styled.p`
color:white;

@media (max-width: 900px) {
  }

`;
