import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { toast, ToastContainer } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import UploadIcon from '@mui/icons-material/Upload';
import axios from 'axios';
import usePostProjectToPlatform from '../../../hooks/usePostProjectToPlatform';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase-config';




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const names = [
  'Freelancer.com',
  // 'Upwork',
  // 'People Per Hour',
  // 'Fiverr',
  // 'Guru',
  // 'Toptal',

];


const skills = [
  { title: 'PHP', id: 3 },
  { title: 'Java', id: 7 },
  { title: 'JavaScript', id: 9 },
  { title: 'Graphic Design', id: 20 },
  { title: 'Copywriting', id: 21 },
  { title: "Logo Design", id: 32 },


];

const currencies = [
  { code: 'USD', id: 1 },
  { code: 'AUD', id: 3 },
  { code: 'EUR', id: 8 },
  { code: 'CAD', id: 9 },


];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const PlatformForm = () => {

  const [projectData, setProjectData] = useState({
    title: "",
    description: '',
    currencyId: null,
    minBudget: null,
    maxBudget: null,
    skillId: null,
    deliverables: ''
  })

  const handleChange = (e) => {
    setProjectData({ ...projectData, [e.target.name]: e.target.value })

  }

  const handlefileChange = (e) => {
    setProjectData({ ...projectData, deliverables: e.target.files[0] })
  }
  const handleCurrencyChange = (e) => {
    setProjectData({ ...projectData,[e.target.name]: e.target.value })
    setCurrency(e.target.value);

  }
  const handleSkillChange = (e) => {
    setProjectData({ ...projectData, [e.target.name]: e.target.value })
    setSkill(e.target.value);

  }

  const[loading,setLoading]=useState(true)



  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  // const UpdateProjectId = async (event) => {
  //   event.preventDefault();
  //   await updateDoc(docRef, {
  //     platformProjectId
  //   });
  //   navigate(`/profile/`);
  // };

  const project = {
    title: 'Hi',
    description: 'hi',
    currencyId: 1,
    minBudget: 10,
    maxBudget: 1000,
    skillId: 3

  }
  const projectId = window.location.pathname.replace("/profile/project/", "");

  const docRef = doc(db, "Projects", projectId);


  const postProject = () => {
    const params = JSON.stringify({
      "title": projectData.title,
      "description": projectData.description,
      "currency": {
        "id": projectData.currencyId
      },
      "budget": {
        "minimum": parseInt(projectData.minBudget),
        "maximum": parseInt(projectData.maxBudget)
      },
      "jobs": [
        {
          "id": projectData.skillId
        }
      ]
    }
    );
    try {
      axios.post("https://www.freelancer-sandbox.com/api/projects/0.1/projects/", params, {
        "headers": {
          "content-type": "application/json",
          "Freelancer-OAuth-V1": "eJtE0Z3kTf8VEATUslJhrr8K4NAmjY",
        }
      })
        .then(res =>  updateDoc(docRef, {
          platformProjectId:res.data.result.id
        })).then(
          setLoading(false)
        ).then(
          toast.success('The project is Submitted successfully', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        )
  
    } catch (error) {
      //console.log(error)
      setLoading(false)
      toast.error('Error Occured , Please try again', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    }
  

  
    
  }







  const [currency, setCurrency] = React.useState('');
  const [skill, setSkill] = React.useState('');



  return (
    <>
      <LoadingOverlay
        //   active={isLoading}
        active={false}
        // spinner={<BounceLoader color="white" />}
        spinner
        
      >
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Grid container
          justifyContent="center"
          spacing={5}
          rowSpacing={1}
        >
          <Grid item xs={12} md={6} >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { mb: 1, mt: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                helperText="Give a name that reflects the essence of your project"
                id="demo-helper-text-misaligned"
                label="Project Title"
                name="title"
                value={projectData.title}
                onChange={(e) => handleChange(e)}
                fullWidth
              />
              <TextField
                id="outlined-helperText"
                label="Project Details "
                helperText="Give a name that reflects the essence of your project"
                name="description"
                value={projectData.description}
                onChange={(e) => handleChange(e)}
                multiline
                rows={5.1}
                fullWidth
              />
              {/* <Button variant="outlined" sx={{ height: '55px' }} fullWidth component="label"
                startIcon={<UploadIcon />}>
                <input
                  type="file"
                  name="deliverables"
                  // accept="imcurrency/*"
                  className="form-control"
                  onChange={(e) => handlefileChange(e)}
                />
              </Button> */}
            </Box>

          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { mb: 1, mt: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              {/* <TextField
                // helperText="Give a name that reflects the essence of your project"
                id="demo-helper-text-misaligned"
                label="What are the skills required for your project?"
                name="title"
                value={formData.title}
                onChange={(e) => handleChange(e)}
                fullWidth
              /> */}

              <FormControl sx={{mb:3}} fullWidth>
                <InputLabel id="demo-simple-select-label">Skills</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={skill}
                  label="Skill"
                  name="skillId"
                  onChange={(e) => handleSkillChange(e)}
                >
                  <MenuItem value={3}>PHP</MenuItem>
                  <MenuItem value={7}>Java</MenuItem>
                  <MenuItem value={9}>Javascript</MenuItem>
                  <MenuItem value={20}>Graphic Design</MenuItem>
                  <MenuItem value={21}>Copywriting</MenuItem>
                  <MenuItem value={32}>Logo Design</MenuItem>
                </Select>
                <FormHelperText>With label + helper text</FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currency}
                  label="Currency"
                  name="currencyId"
                  onChange={(e) => handleCurrencyChange(e)}
                >
                  <MenuItem value={1}>USD</MenuItem>
                  <MenuItem value={3}>AUD</MenuItem>
                  <MenuItem value={8}>EUR</MenuItem>
                  <MenuItem value={9}>CAD</MenuItem>
                </Select>
                <FormHelperText>With label + helper text</FormHelperText>
              </FormControl>
              {/* <TextField
                // helperText="Give a name that reflects the essence of your project"
                id="demo-helper-text-misaligned"
                label="What is your budget?"
                name="title"
                value={formData.title}
                onChange={(e) => handleChange(e)}
                fullWidth
              /> */}
              <Box fullWidth sx={{ display: 'flex', mt: 2 }}>
                <FormControl fullWidth >
                  <InputLabel htmlFor="outlined-adornment-amount">Min Budget</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="Min Budget"
                    name="minBudget"
                    value={projectData.minBudget}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>
                <FormControl fullWidth >
                  <InputLabel htmlFor="outlined-adornment-amount">Max Budget</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="Max Budget"
                    name="maxBudget"
                    value={projectData.maxBudget}
                    onChange={(e) => handleChange(e)}
                  />
                </FormControl>

              </Box>

            </Box>
            <FormControl fullWidth
              sx={{ mb: 8.5, mt: 1 }}
            >
              <InputLabel id="demo-multiple-chip-label">Freelancing Platforms</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={personName}
                onChange={handleSelectChange}
                input={<OutlinedInput id="select-multiple-chip" label="FreelancingPlatforms" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select Freelancing Platforms to Publish your Project</FormHelperText>

            </FormControl>

            <Button onClick={()=>postProject()} type='submit' variant="contained">Submit Project</Button>

          </Grid>
        </Grid>

      </LoadingOverlay>

    </>
  )
}

export default PlatformForm