import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Files.css";
import profilePicture from "../../images/profilePicture.png";
import homeIcon from "../../images/homeIcon.png";
import projectsIcon from "../../images/projectsIcon.png";
import paymentIcon from "../../images/paymentIcon.png";
import settingsIcon from "../../images/settingsIcon.png";
import SideMenu from "../Basic/SideMenu/SideMenu";

const Files = ({projectList , isAdmin}) => {
  const projectId = window.location.pathname.replace("/profile/project/", "");
  const projectIdd = projectId.replace("/files", "");
  const proj = projectList.filter((project) => project.id === projectIdd);
  const [title, setTitle] = useState(proj[0].title);
  const [deliverables, setDeliverables] = useState(proj[0].deliverables);


  const status = "Approved";

  return (
    <div className="main-profile-container">
  <SideMenu/>

    <div className="files-main-container">
      <p className="project-title-name">{title}</p>
      {status === "Approved" && (
        <div className="project-page-nav">
          <button>
            <Link to={`/profile/project/${projectIdd}/`}>Details</Link>{" "}
          </button>
          <button>Payment</button>
          <button>
            {" "}
            <Link to={`./`}>Files</Link>
          </button>
          <button>Feedback</button>
        </div>
      )}
      <div className="files-download">
        <p>Project deliverables</p>
        <button><a target='_blank' href={deliverables}>Download</a></button>
      </div>
    </div>
    </div>
  );
};

export default Files;
