import React, { useState } from 'react'

import SideMenu from '../Basic/SideMenu/SideMenu';

const PlatformInterface = ({ projectList, isAdmin }) => {



    return (
        <>
            <div className="main-profile-container">
                <SideMenu />
            </div>

        </>
    )
}

export default PlatformInterface