import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PlatformForm from '../PlatformForm/PlatformForm';
import LoadingOverlay from 'react-loading-overlay';
import { useEffect } from 'react';
import axios from 'axios';
import { Avatar, Divider, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { SiFreelancer } from 'react-icons/si';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { BounceLoader } from 'react-spinners';


export default function SelectFreelancers({projectId}) {

  const [projectBids, setProjectBids] = React.useState()
  const [projectBidders, setProjectBidders] = React.useState()
  //console.log(projectBidders)
  // projectBidders && //console.log(projectBidders['40020806'].public_name)
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    try {
      axios.get(`https://www.freelancer-sandbox.com/api/projects/0.1/projects/${projectId}/bids/?user_details&user_avatar`, {
      //  axios.get(`https://www.freelancer.com/api/projects/0.1/projects/${projectId}/bids/?user_details&user_avatar`, {
        //  axios.get(`https://www.freelancer.com/api/projects/0.1/projects/36419073/bids/?user_details&user_avatar`, {
        //  axios.get(`https://www.freelancer.com/api/projects/0.1/projects/33742114/bids/?user_details&user_avatar`, {
      //  axios.get(`https://www.freelancer.com/api/projects/0.1/projects/35357360/bids/?user_details&user_avatar`, {
        headers: {
          "content-type": "application/json",
          "Freelancer-OAuth-V1": "eJtE0Z3kTf8VEATUslJhrr8K4NAmjY",
        }
      })
        .then(res => {
          setProjectBids(res.data.result.bids)
          setProjectBidders(res.data.result.users)
          setLoading(false)


        }
        )

    } catch (error) {
      //console.log(error)

    }
  }, []);














  return (

    <LoadingOverlay
      active={loading}
      spinner={<BounceLoader color="white" />}


    >
 <div style={{ height: '500px', overflowY: 'scroll' , alignItems:'center', display:'flex' , justifyContent:'center'}}>
 {projectBids?.length>0 && !loading ?  
        <List sx={{ width: '100%', bgcolor: 'background.paper', margin:2 }}>
          {projectBids && projectBidders && projectBids.map((bid) => (
            <Link target='_blank' href={`https://www.freelancer.com/u/${projectBidders[bid.bidder_id].username}`}>
            <ListItem alignItems="flex-start">
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar sx={{width:'60px',height:'60px',marginRight:3}} alt="Remy Sharp" src={projectBidders[bid.bidder_id].avatar_cdn} />
                </ListItemAvatar>
                <ListItemText
                sx={{width:'70%'}}
                  primary={projectBidders[bid.bidder_id].public_name}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {projectBidders[bid.bidder_id].display_name}
                      </Typography>
                       - {bid.description}
                    </React.Fragment>
                  }
                />
                <ListItemText
                  primary= {<React.Fragment>
                  <Typography
                    sx={{ display: 'inline' , fontSize:20 ,ml:3 ,fontWeight:'600'}}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    ${bid.amount} USD

                  </Typography>
                </React.Fragment>}
                />
              </ListItemButton>
            </ListItem>
            </Link>
          ))

          }
        </List>: <Typography sx={{fontSize:20}}>No Bids </Typography>}
      </div>
    
    </LoadingOverlay>
  );
}