import React, { useState } from 'react'
import homeIcon from "../../images/homeIcon.png";
import projectsIcon from "../../images/projectsIcon.png";
import paymentIcon from "../../images/paymentIcon.png";
import settingsIcon from "../../images/settingsIcon.png";
import creditCard from "../../images/creditCard.png";
import stripePayment from "../../images/stripePayment.png";
import profilePicture from "../../images/profilePicture.png";
import { Link, useNavigate } from 'react-router-dom';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase-config';
import SideMenu from '../Basic/SideMenu/SideMenu';

const Success = ({projectList , isAdmin}) => {
    const projectId = window.location.pathname.replace("/profile/project/", "");
    const projectIdd = projectId.replace("/payment/success", "");

    
    const navigate = useNavigate()

 
    const redirectPage =()=>{
        setTimeout( ()=> navigate('/profile'), 5000)
    }
    
    redirectPage()
    
  return (
    <div className="main-profile-container">
    <SideMenu/>

      <div className="files-main-container">
   
          <h1 style={{color:'green'}}>Payment is succeeded &#10003; </h1>
          <br/>
          <p style={{marginBottom:'50px'}}>Redirecting to Projects Page ....</p>
   
        </div>
      </div>    
  )
  }

export default Success