import React from 'react'
import { Navigate } from 'react-router-dom'
import Form from '../components/Form/Form'
import { UserAuth } from '../contexts/AuthContext'

const PostProject = () => {


  const { user } = UserAuth();
  if (!user) {
    return <Navigate to='/login' />;
  }

  return (
   <Form /> 
  )
}

export default PostProject