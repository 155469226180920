import * as React from 'react';
import Countdown from 'react-countdown';
import { CountdownContainer, DevelopmentText, HeaderContainer } from './FeedbackStyles';
import { useRef } from 'react';
import { useContext } from 'react';
import ProjectsContext from '../../../contexts/ProjectsContext';
import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../../firebase-config';
import { Box, Button, TextField } from '@mui/material';
import { useEffect } from 'react';
import { AiOutlineFileText } from 'react-icons/ai';




export default function Feedback({ deliverables }) {

  console.log("files", deliverables)

  const { projectList, loading } = useContext(ProjectsContext);

  const projectId = window.location.pathname.replace("/profile/project/", "");
  const docRef = doc(db, "Projects", projectId);

  const proj = projectList.filter((project) => project.id === projectId);

  const [comments, setComments] = useState();

  useEffect(() => {
    setComments(proj[0].comments);
  }, []);
  const [comment, setComment] = useState();

  const CurrentUser = proj[0].author.id === auth.currentUser?.uid ? true : false

  const dummy = useRef();

  const UpdateMessages = async (event) => {
    event.preventDefault();
    CurrentUser ? setComments([...comments, `Me : ${comment}`]) : setComments([...comments, `Admin : ${comment}`])

    dummy.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });

    CurrentUser ? await updateDoc(docRef, { comments: [...comments, `Me : ${comment}`], })
      : await updateDoc(docRef, { comments: [...comments, `Admin : ${comment}`], });



    setComment('');
  };

  // const messagesRef = firestore.collection('messages');
  // const query = messagesRef.orderBy('createdAt').limit(25);

  // const [messages] = useCollectionData(query, { idField: 'id' });

  // const [formValue, setFormValue] = useState('');


  // const sendMessage = async (e) => {
  //   e.preventDefault();

  //   const { uid, photoURL } = auth.currentUser;

  //   await messagesRef.add({
  //     text: formValue,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //     uid,
  //     photoURL
  //   })

  //   setFormValue('');
  //   dummy.current.scrollIntoView({ behavior: 'smooth' });
  // }


  function ChatMessage({ message }) {
    // const { text, uid, photoURL } = props.message;

    // const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

    return (<>
      <div >
        <img src={'https://api.adorable.io/avatars/23/abott@adorable.png'} />
        {CurrentUser ? <p >{message}</p> : <p>{message}</p>}
      </div>
    </>)
  }
  return (
    <>
      <Box sx={{ overflow: "auto", height: 250, mb: 2 }}>
        {deliverables?.map((item) => (
          <Box sx={{ display: 'flex', alignItems: 'start', mt: 2, flexDirection: 'column' }}>
            <p className='mb-5 text-sm'>You will be Eligable to Leave a comment when the project completed </p>
            <TextField disabled fullWidth multiline rows={4} id="outlined-basic" label="Feedback" variant="outlined" />
            <Button disabled>Submit</Button>
          </Box>

        ))}
      </Box>
    </>
  )
}