import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PlatformForm from '../PlatformForm/PlatformForm';
import LoadingOverlay from 'react-loading-overlay';
import { useEffect } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { SiFreelancer } from 'react-icons/si';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SelectFreelancers from '../SelectFreelancers/SelectFreelancers';
import Iframe from 'react-iframe';
import ProjectsContext from '../../../contexts/ProjectsContext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //   width: 400,
  bgcolor: 'background.paper',
  //  border: '.5px solid #000',
  borderRadius: 1,
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // alignItems:"center",
  p: 4,
};


export default function SubmitPlatformProject() {

  const [projectIdInPlatform, setProjectIdInPlatform] = useState()

  console.log('test',projectIdInPlatform )

  const steps = [
    {
      label: 'Select Freelancers',
      component: <SelectFreelancers projectId={projectIdInPlatform} />,
    },
    {
      label: 'Freelancer',

      component: <Button>step 2</Button>,
    },
    {
      label: 'Hire FReelancers',
      component: <Button>step 3</Button>,
    },
  ];

  const [projectData, setProjectData] = React.useState()
  //console.log(projectData)
  const [loading, setLoading] = React.useState(true)



  const { projectList } = React.useContext(ProjectsContext);

  const projectId = window.location.pathname.replace("/profile/project/", "");


  useEffect(() => {
    const proj = projectList?.filter((project) => project.id === projectId);

    if (proj[0].platformProjectId) {
      setProjectIdInPlatform(proj[0].platformProjectId)

      try {
        axios.get(`https://www.freelancer-sandbox.com/api/projects/0.1/projects/${proj[0].platformProjectId}`, {
          // axios.get(`https://www.freelancer.com/api/projects/0.1/projects/${proj[0].platformProjectId}`, {
          //  axios.get(`https://www.freelancer.com/api/projects/0.1/projects/36419073`, {
          // axios.get(`https://www.freelancer.com/api/projects/0.1/projects/33742114`, {
          // axios.get(`https://www.freelancer.com/api/projects/0.1/projects/35357360`, {
          headers: {
            "content-type": "application/json",
            "Freelancer-OAuth-V1": "eJtE0Z3kTf8VEATUslJhrr8K4NAmjY",
          }
        })
          .then(res => {
            setLoading(false)
            setProjectData(res.data)

          }
          )

      } catch (error) {
        //console.log(error)

      }
    }
  }, []);

  const navigate = useNavigate();


  const [open, setOpen] = React.useState(false);
  const [openFreelancersPage, setOpenFreelancersPage] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenFreelancersPage = () => {
    if (projectData?.result?.frontend_project_status === 'open' || projectData?.result?.frontend_project_status === 'pending') {
      setOpenFreelancersPage(true)
    } else {
      window.open(`https://www.freelancer.com/projects/${projectData?.result.seo_url}`, '_blank');
    }
  }
  const handleCloseFreelancersPage = () => setOpenFreelancersPage(false);






  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };









  const [projectBids, setProjectBids] = React.useState()
  const [projectBidders, setProjectBidders] = React.useState()
  // projectBidders && //console.log(projectBidders['40020806'].public_name)
  useEffect(() => {
    const proj = projectList.filter((project) => project.id === projectId);

    try {
      axios.get(`https://www.freelancer-sandbox.com/api/projects/0.1/projects/${proj[0].platformProjectId}/bids/?user_details&user_avatar`, {
        // axios.get(`https://www.freelancer.com/api/projects/0.1/projects/${proj[0].platformProjectId}/bids/?user_details&user_avatar`, {
        //  axios.get(`https://www.freelancer.com/api/projects/0.1/projects/36419073/bids/?user_details&user_avatar`, {
        //  axios.get(`https://www.freelancer.com/api/projects/0.1/projects/33742114/bids/?user_details&user_avatar`, {
        //  axios.get(`https://www.freelancer.com/api/projects/0.1/projects/35357360/bids/?user_details&user_avatar`, {
        headers: {
          "content-type": "application/json",
          "Freelancer-OAuth-V1": "eJtE0Z3kTf8VEATUslJhrr8K4NAmjY",
        }
      })
        .then(res => {
          setProjectBids(res.data?.result.bids)
          setProjectBidders(res.data?.result.users)
          //console.log(res.data.result)

          setLoading(false)


        }
        )

    } catch (error) {
      //console.log(error)

    }
  }, []);










  return (
    <div >
      <Box sx={{ width: '100%' }}>
        {!loading && <nav aria-label="main mailbox folders">
          {projectIdInPlatform && <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleOpenFreelancersPage}>
                <ListItemIcon>
                  <SiFreelancer />
                </ListItemIcon>
                <ListItemText primary={projectData?.result.title} />
                <ListItemText primary={`Project Status : ${projectData?.result?.frontend_project_status}`} />
                <ListItemText primary={`Bids : ${projectBids?.length} bids`} />
                {/* {projectData.result.frontend_project_status==='work_in_progress' && <ListItemText  primary={`Freelancer : ${projectBids?.length} bids`} />} */}
              </ListItemButton>
            </ListItem>
          </List>}
        </nav>}
      </Box>
      <Typography sx={{ marginTop: 10, fontSize: 14 }}>Submit The project to Freelancing Platforms</Typography>
      <Button variant="contained" sx={{ width: '25%', marginTop: 1 }} onClick={handleOpen}>Submit</Button>
      <Modal

        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='w-screen'>
          <div className='bg-white m-5 p-5 lg:m-32 lg:p-20' >
            <div className='flex justify-between items-center mb-10'>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: '600'
              }}
            >Submit Project To Freelancing Platforms</Typography>
            <AiOutlineCloseCircle size={30}
            onClick={handleClose}
            />
            </div>
      

            <PlatformForm />

          </div>
        </div>
      </Modal>
      <Modal
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        open={openFreelancersPage}
        onClose={handleCloseFreelancersPage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ maxWidth: 1000, flexGrow: 1, backgroundColor: 'white' }}>
          <SelectFreelancers projectId={projectIdInPlatform} />
        </Box>
      </Modal>
    </div>
  );
}