import { collection, getDocs } from "firebase/firestore";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { auth, db } from "../firebase-config";
import { query, where } from "firebase/firestore";
import { UserAuth } from "../contexts/AuthContext";
import { async } from "@firebase/util";
import { onAuthStateChanged } from "firebase/auth";


export const INITIAL_STATE = Object.freeze({
  projects: [],
});
const useProjects = () => {
  const [user, setUser] = useState({})
  const [isAdmin, setIsAdmin] = useState({})

  const [error, setError] = useState(null);
  const handleError = useCallback(
    (e) => {
      setError(e);
    },
    [setError]
  );
  useEffect(
    () => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
            currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    //console.log(idTokenResult)
                    //  Confirm the user is an Admin.
                    if (idTokenResult.claims.admin) {
                        console.log("Admin User")
                        setIsAdmin(true)
                    } else {
                        //  Show regular user UI.
                        console.log("Client User ")
                        setIsAdmin(false)

                    }
                })
                .catch((error) => {
                    //console.log(error);
                });
        })
        return () => {
            unsubscribe()
        }
    },
    [],
)

  useEffect(() => {
    isAdmin ? getProjects(): getUserProjects(user.uid)
  },[isAdmin])
  
  const [projectList, setProjectList] = useState([]);
  const [projectListByUid, setProjectListByUid] = useState([]);
  const[loading,setLoading]=useState(true)
  const projectsCollectionRef = collection(db, "Projects");

    const getProjects = async () => {
      console.log("admin projects")
      const data = await getDocs(projectsCollectionRef);
      if (data) {
        try {
          setLoading(false)
          setProjectList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

        } catch (e) {
          setError("Something went wrong.");
        }

      }
    };

    const getUserProjects = async (uid) => {
      console.log("user projects")

      const q = query(collection(db, "Projects"), where("author.id", "==", uid));
        const querySnapshot = await getDocs(q);
        const arr = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          data.id =doc.id
          arr.push(data)
        });
        setProjectList(arr)
setLoading(false)
    };
    
    
    
   
    
   
    

  return {
    projectList ,loading,getProjects,getUserProjects,projectListByUid
  };
};






export {
 useProjects,

}

