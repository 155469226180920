import React, { useContext, useEffect, useState } from "react";

import { doc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase-config";
import profilePicture from "../../images/profilePicture.png";
import homeIcon from "../../images/homeIcon.png";
import projectsIcon from "../../images/projectsIcon.png";
import paymentIcon from "../../images/paymentIcon.png";
import settingsIcon from "../../images/settingsIcon.png";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./ProjectPage.css";
import { Link, useNavigate } from "react-router-dom";
import Comments from "../Comments/Comments";
import SideMenu from "../Basic/SideMenu/SideMenu";
import ProjectsContext from "../../contexts/ProjectsContext";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Form from "../Form/Form";
import PlatformInterface from "../../Containers/PlatformInterface/PlatformInterface";
import Checkout from "../Checkout/Checkout";
import ChatRoom from "../Basic/ChatRoom/ChatRoom";
import Lottie from "lottie-react";
import pending from "./pending.json";
import Success from "./Success.json";

import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { UserAuth } from "../../contexts/AuthContext";
import Files from "../Basic/Files/Files";
import Feedback from "../Basic/Feedback/Feedback";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ProjectPage = () => {
  const { isAdmin } = UserAuth();
  const { projectList, loading, getProjects, getUserProjects } =
    useContext(ProjectsContext);
  const projectId = window.location.pathname.replace("/profile/project/", "");
  const proj = projectList.filter((project) => project.id === projectId);

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [subValue, setSubValue] = React.useState("1");

  const handleChangeSub = (event, newSubValue) => {
    setSubValue(newSubValue);
  };

  const [title, setTitle] = useState();
  const [reason, setReason] = useState();
  const [constraints, setConstraints] = useState();
  const [skills, setSkills] = useState();
  const [details, setDetails] = useState();
  const [deliverables, setDeliverables] = useState();
  const [requiredDeliverables, setRequiredDeliverables] = useState();
  const [email, setEmail] = useState();
  const [comments, setComments] = useState();
  const [comment, setComment] = useState();
  const [status, setStatus] = useState();
  const [budget, setBudget] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [deposite, setDeposite] = useState();
  const [complete, setComplete] = useState();
  const [fullPayment, setFullPayemnt] = useState();

  function timeConverter(UNIX_timestamp){
    const a = new Date(UNIX_timestamp * 1000);
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const year = a.getYear()-70;
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const min = a.getMinutes();
    const sec = a.getSeconds();
    const time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }



  useEffect(() => {


    if (!loading) {
      setTitle(proj[0].title);
      setReason(proj[0].reason);
      setConstraints(proj[0].constraints);
      setSkills(proj[0].skills);
      setDetails(proj[0].details);
      setDeliverables(proj[0].deliverables);
      setRequiredDeliverables(proj[0].requiredDeliverables);
      setEmail(proj[0].email);
      setComments(proj[0].comments);
      setStatus(proj[0].status);
      setBudget(proj[0].budget);
      setDeposite(proj[0].deposite);
      setComplete(proj[0].complete);
      setFullPayemnt(proj[0].fullPayment);
      setStartDate(proj[0].startDate);
      setEndDate(proj[0].endDate);
    }
  }, [loading]);
  const navigate = useNavigate();

  const docRef = doc(db, "Projects", projectId);
  const updateProject = async (event) => {
    event.preventDefault();
    await updateDoc(docRef, {
      title,
      reason,
      constraints,
      skills,
      details,
      requiredDeliverables,
      email,
      budget
    });
    navigate("/profile");
  };

  const UpdateMessages = async (event) => {
    event.preventDefault();
    await updateDoc(docRef, {
      comments: [...comments, comment],
    });
    navigate(`/profile/`);
  };

  const handleApprove = async (event) => {
    event.preventDefault();
    await updateDoc(docRef, {
      status: "Approved",
    });
    navigate("/profile");
  };
  const markAsCompleted = async (event) => {
    event.preventDefault();
    await updateDoc(docRef, {
      complete: true,
    });
    navigate("/profile");
  };

console.log('ddddd',timeConverter(startDate))


  return (
    <>
      <LoadingOverlay active={loading} spinner={<BounceLoader color="white" />}>
        <div className="main-profile-container ">
          <SideMenu />
          <div className="project-page-maincontainer   ">
            <h2 className="project-title-name px-10 text-center lg:text-start">{title}</h2>
            {/* 
            {status === "Approved" && (
              <div className="project-page-nav">
                <button>Details</button>
                <button>
                  <Link to={`/profile/project/${projectId}/payment`}>Payment</Link>
                </button>
                <button>
                  <Link to={`/profile/project/${projectId}/platform`}>Platform Interface</Link>
                </button>
                <button>
                  {" "}
                  <Link to={`/profile/project/${projectId}/files`}>Files</Link>
                </button>
                <button>Feedback</button>
              </div>
            )} */}
            <TabContext value={value}>
              <div className="w-screen">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    variant="scrollable"
                    // scrollButtons="auto"
                    allowScrollButtonsMobile

                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Project Details" value="1" />
                    <Tab label="Payment" value="2" />
                    {isAdmin && <Tab label="Platform Interface" value="3" />}
                    <Tab label="Files" value="4" />
                    <Tab label="Feedback" value="5" />
                    <Tab label="Chat" value="6" />
                  </TabList>
                </Box>
              </div>

              <TabPanel value="1">
                <div className="form-container w-screen ">
                  <div className="project-container">
                    <form onSubmit={updateProject}>
                      <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Notifications
                          </h2>

                          <div className="col-span-full">
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                              <div className="text-center">
                                <div className="px-20 lg:px-96 ">
                                  {
                                    status === "Approved" &&
                                    !complete && (<Lottie animationData={Success} />)
                                  }
                                  {
                                    !(status === "Approved") &&
                                    !complete && (<Lottie animationData={pending} />)
                                  }
                                </div>

                                <div>
                                  {/* <h3>Comments</h3> */}
                                  <h3> Project Status</h3>
                                  {!isAdmin && fullPayment && !complete && (
                                    <FormControlLabel
                                      value="end"
                                      control={
                                        <Checkbox onChange={markAsCompleted} />
                                      }
                                      label="Mark as Completed"
                                      labelPlacement="end"
                                    />
                                  )}

                                  <br />
                                  {/* <Comments isAdmin={isAdmin} comments={comments} />  */}
                                  <br />
                                  <br />

                                  {/* <form className="comments-form" onSubmit={UpdateMessages}>
                      <input
                        className="name"
                        type="text"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Add Comment"
                      />

                      <input className="submit-btn" type="submit" value="Reply" />
                    </form> */}
                                  {!isAdmin && status !== "Approved" && (
                                    <p className="flex items-center  justify-center text-center " >
                                      Your Project is Under Review , we will get
                                      in touch with you soon..{" "}
                                    </p>

                                  )}
                                  {isAdmin && status !== "Approved" && (
                                    <Button
                                      onClick={handleApprove}
                                      className="submit-btn accept-btn"
                                      value="Approve"
                                    >
                                      Accept
                                    </Button>
                                  )}
                                  {isAdmin &&
                                    status === "Approved" &&
                                    !complete && (
                                      <>
                                        <Typography className="project-approved-mark ">
                                          Project Aprroved
                                        </Typography>
                                        {deposite === true ? (
                                          <>
                                            <p className="payment-request project-approved-mark ">
                                              &#10003; Deposite Payment
                                            </p>
                                            <p
                                              style={{
                                                color: "red",
                                                textDecoration: "underline",
                                              }}
                                              className="payment-request  "
                                            >
                                              &#9679; Work under Progress
                                            </p>
                                          </>
                                        ) : (
                                          <p className="waiting-payment ">
                                            &#x2717; Deposite Payment
                                          </p>
                                        )}
                                      </>
                                    )}
                                  {fullPayment && (
                                    <p className="payment-request project-approved-mark ">
                                      &#10003; Full Payment
                                    </p>
                                  )}
                                  {complete && (
                                    <p className="payment-request project-approved-mark ">
                                      &#10003; Project Completed
                                    </p>
                                  )}

                                  {!isAdmin &&
                                    status === "Approved" &&
                                    !complete && (
                                      <>
                                        <p className="project-approved-mark ">
                                          &#10003; Project Aprroved
                                        </p>

                                        {deposite === true ? (
                                          <>
                                            <p className="payment-request project-approved-mark ">
                                              &#10003; Deposite Payment
                                            </p>
                                            <p
                                              style={{
                                                color: "red",
                                                textDecoration: "underline",
                                              }}
                                              className="payment-request  "
                                            >
                                              &#9679; Work under Progress
                                            </p>
                                          </>
                                        ) : (
                                          <p className="payment-request text-red-500">

                                            &#9679; You need to pay a deposite from Payment Tab
                                          </p>
                                        )}
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-gray-900/10 pb-12">
                          <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Project Information
                          </h2>

                          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="first-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Project Title
                              </label>
                              <label
                                htmlFor="first-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                <label className="sub-label">
                                  Give a name that reflects the essence of your
                                  project
                                </label>{" "}
                              </label>
                              <div className="mt-2">
                                <input
                                  className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  type="text"
                                  disabled={
                                    (isAdmin || status === "Approved") && true
                                  }
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                // placeholder={title}
                                // placeholder={proj[0].title}
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Why are you running this project?
                              </label>
                              <div className="mt-8">
                                <input
                                  className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  disabled={
                                    (isAdmin || status === "Approved") && true
                                  }
                                  type="text"
                                  value={reason}
                                  onChange={(e) => setReason(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-span-full">
                              <label
                                htmlFor="about"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Project Details
                              </label>
                              <div className="mt-2">
                                <textarea
                                  className="block p-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  disabled={
                                    (isAdmin || status === "Approved") && true
                                  }
                                  type="text"
                                  value={details}
                                  onChange={(e) => setDetails(e.target.value)}
                                  rows={3}
                                />
                              </div>
                              <p className="mt-3 text-sm leading-6 text-gray-600">
                                Write a few sentences about yourself.
                              </p>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                What are the skills required for your project?
                              </label>
                              <div className="mt-2">
                                <input
                                  className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  disabled={
                                    (isAdmin || status === "Approved") && true
                                  }
                                  type="text"
                                  value={skills}
                                  onChange={(e) => setSkills(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="last-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                What are the project biggest constraints ?
                              </label>
                              <div className="mt-2">
                                <input
                                  className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  disabled={
                                    (isAdmin || status === "Approved") && true
                                  }
                                  type="text"
                                  value={constraints}
                                  onChange={(e) =>
                                    setConstraints(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Email address
                              </label>
                              <div className="mt-2">
                                <input
                                  autoComplete="email"
                                  className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  disabled={
                                    (isAdmin || status === "Approved") && true
                                  }
                                  type="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="country"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Describe the expected deliverables{" "}
                              </label>
                              <div className="mt-2">
                                <input
                                  className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  disabled={
                                    (isAdmin || status === "Approved") && true
                                  }
                                  type="text"
                                  value={requiredDeliverables}
                                  onChange={(e) =>
                                    setRequiredDeliverables(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="country"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Project Budget (USD)
                              </label>
                              <div className="mt-2">
                                <input
                                  className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  disabled={
                                    (isAdmin || status === "Approved") && true
                                  }
                                  type="text"
                                  value={budget}
                                  onChange={(e) => setBudget(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-span-full">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Start Date
                                </label>
                                <DesktopDatePicker
                                  // label="Start Date"
                                  value={timeConverter(startDate)}
                                  minDate={dayjs("2017-01-01")}
                                  // disabled={
                                  //   (isAdmin || status === "Approved") && true
                                  // }
                                  disabled

                                  onChange={(newValue) => {
                                    setStartDate(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-span-full">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  End Date
                                </label>
                                <DesktopDatePicker
                                  // label="End Date"
                                  // disabled={
                                  //   (isAdmin || status === "Approved") && true
                                  // }
                                  disabled
                                  value={timeConverter(endDate)}
                                  minDate={dayjs("2017-01-01")}
                                  onChange={(newValue) => {
                                    setEndDate(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 flex items-center justify-end gap-x-6">

                        {((!isAdmin || !(status === "Approved"))) && <button
                          type="submit"
                          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Save
                        </button>}

                      </div>
                    </form>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <Checkout isAdmin={isAdmin} />
              </TabPanel>
              <TabPanel value="3">
                <TabContext value={subValue}>
                  <PlatformInterface />
                  {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeSub} aria-label="lab API tabs example">
                  <Tab label="Project Details" subValue="1" />
                  <Tab label="Payment" subValue="2" />
                  <Tab label="Platform Interface" subValue="3" />
                  <Tab label="Files" subValue="4" />
                  <Tab label="Feedback" subValue="5" />
                </TabList>
              </Box> */}
                  <TabPanel subValue="1"></TabPanel>
                  <TabPanel subValue="2">Item two</TabPanel>
                  <TabPanel subValue="3">Item Three</TabPanel>
                </TabContext>
              </TabPanel>
              <TabPanel value="4">
                <Files deliverables={deliverables} />
              </TabPanel>
              <TabPanel value="5">
                <Feedback deliverables={deliverables} />
              </TabPanel>
              <TabPanel value="6">
                <ChatRoom comments={comments} />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default ProjectPage;
