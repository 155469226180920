import { auth, provider } from "../firebase-config";
import { signInWithPopup } from "firebase/auth";
import { useHistory, useNavigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import { FcGoogle } from 'react-icons/fc'
// import logo from '../images/logo.png'
import './Login.css'
import { useEffect } from "react";
import { icons } from "../utils/icons";

const Login = ({ setIsAuth }) => {
  const { googleSignIn, user } = UserAuth()
  let navigate = useNavigate();
  const handleSignInWithGoogle = async () => {

    try {
      await googleSignIn()
    } catch (error) {
      //console.log(error)
    }
    // signInWithPopup(auth, provider).then((result) => {
    //   localStorage.setItem("isAuth", true);
    //   setIsAuth(true);
    //   navigate('/profile')

    // });
  };


  useEffect(() => {
    if (user != null) {
      navigate('/profile')
    }
  }, [user])


  return (
    <div className="form-section h-screen flex items-center justify-center">
      <div className="flex  flex-col items-center justify-between border-white	border-4	m-5 p-20 border-solid	rounded-lg	text-white  ">
        <img className="logo-image" src={icons.Logo} alt='' />
          <p className="text-3xl  mt-20 mb-5">Log In</p>
          <button  className="login-with-google-btn p-3 rounded-lg text-sm lg:text:lg cursor-pointer" onClick={handleSignInWithGoogle}>
            <FcGoogle className="google-logo" />
            Sign In With Google
          </button>

      </div>
    </div>
  );
};

export default Login;
