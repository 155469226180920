import React, { useState } from "react";
import { signOut } from "firebase/auth";
import { FaTimes } from "react-icons/fa";
import { CgMenuRight } from "react-icons/cg";
import { IconContext } from "react-icons";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavItem,
} from "./NavbarStyles.js";
import { useLocation, useHistory, useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config.js";
import { Button } from "@mui/material";
import { UserAuth } from "../../contexts/AuthContext.js";
import { icons } from "../../utils/icons.js";

const Navbar = ({ isAuth, setIsAuth }) => {

  const { user, logout } = UserAuth()
  const [show, setShow] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  const handleClick = () => {
    setShow(!show);
  };

  const scrollTo = (id) => {
    const element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  const closeMobileMenu = (to, id) => {
    if (id && location.pathname === "/") {
      scrollTo(id);
    }

    navigate(to);
    setShow(false);
  };

  const signOutUser = async () => {

    try {
      await logout()
    } catch (error) {
      //console.log(error)
    }
    // signOut(auth).then(() => {
    //   localStorage.clear()
    //   setIsAuth(false)
    //   navigate("/login");
    //   //console.log(auth)


    // })
  }

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <NavIcon src={icons.Logo} alt="logo" />
          </NavLogo>
          <MobileIcon onClick={handleClick}>
            {show ? <FaTimes /> : <CgMenuRight />}
          </MobileIcon>
          <NavMenu show={show}>
            <NavItem>
              <NavLinks onClick={() => closeMobileMenu("/", "how")}>
                How it works
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks onClick={() => closeMobileMenu("/", "experts")}>
                Our Experts
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks onClick={() => closeMobileMenu("/", "about")}>
                About
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks onClick={() => closeMobileMenu("/postProject")}>
                Post Project
              </NavLinks>
            </NavItem>
            {user?.displayName ? (
              <>
                <NavItem>
                  <NavLinks onClick={() => closeMobileMenu("/profile")}>
                    Profile
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks onClick={signOutUser}>
                    Log out
                  </NavLinks>
                </NavItem>
              </>
            ) : (
              <NavItem>
                <NavLinks onClick={() => closeMobileMenu("/login")}>
                  Login
                </NavLinks>
              </NavItem>

            )}
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default Navbar;
