import * as React from 'react';
import Countdown from 'react-countdown';
import { CountdownContainer, DevelopmentText, HeaderContainer } from './ChatRoomStyles';
import { useRef } from 'react';
import { useContext } from 'react';
import ProjectsContext from '../../../contexts/ProjectsContext';
import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../../firebase-config';
import { Box, Button, TextField } from '@mui/material';
import { useEffect } from 'react';


export default function ChatRoom({ }) {

  const { projectList, loading } = useContext(ProjectsContext);

  const projectId = window.location.pathname.replace("/profile/project/", "");
  const docRef = doc(db, "Projects", projectId);

  const proj = projectList.filter((project) => project.id === projectId);

  const [comments, setComments] = useState();

  useEffect(() => {
    setComments(proj[0].comments);
  }, []);
  const [comment, setComment] = useState();

  const CurrentUser = proj[0].author.id === auth.currentUser?.uid ? true : false

  const dummy = useRef();

  const UpdateMessages = async (event) => {
    event.preventDefault();
    CurrentUser ? setComments([...comments, `Me : ${comment}`]) : setComments([...comments, `Admin : ${comment}`])

    dummy.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });

    CurrentUser ? await updateDoc(docRef, { comments: [...comments, `Me : ${comment}`], })
      : await updateDoc(docRef, { comments: [...comments, `Admin : ${comment}`], });



    setComment('');
  };

  // const messagesRef = firestore.collection('messages');
  // const query = messagesRef.orderBy('createdAt').limit(25);

  // const [messages] = useCollectionData(query, { idField: 'id' });

  // const [formValue, setFormValue] = useState('');


  // const sendMessage = async (e) => {
  //   e.preventDefault();

  //   const { uid, photoURL } = auth.currentUser;

  //   await messagesRef.add({
  //     text: formValue,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //     uid,
  //     photoURL
  //   })

  //   setFormValue('');
  //   dummy.current.scrollIntoView({ behavior: 'smooth' });
  // }


  function ChatMessage({ message }) {
    // const { text, uid, photoURL } = props.message;

    // const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

    return (
      <>
        <div >
          {/* <img src={'https://api.adorable.io/avatars/23/abott@adorable.pn'} /> */}
          {CurrentUser ? <p className='mb-5' >{message}</p> : <p className='mb-5'>{message}</p>}
        </div>
      </>
    )
  }
  return (
    <>
      <Box sx={{ overflow: "auto", height: 194, mb: 2 }}>
        {comments && comments.map(msg => <ChatMessage key={msg.id} message={msg} />)}

        <span ref={dummy}></span>

      </Box>
      <form onSubmit={UpdateMessages} >

        {/* <input value={comment} onChange={(e) => setComment(e.target.value)} placeholder="say something nice" />   */}
        <Box sx={{ display: 'flex' }}>
          <TextField id="outlined-basic" variant="outlined" value={comment} onChange={(e) => setComment(e.target.value)} placeholder="say something nice" />

          <Button disabled={!comment} color={'success'} type="submit" >Send</Button>
        </Box>
      </form>
    </>
  )
}