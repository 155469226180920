import React, { useEffect, useState } from "react";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import "./Form.css";
import { db, auth, storage } from "../../firebase-config";
import { useHistory, useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Box,
  Button,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import LoadingOverlay from "react-loading-overlay";
import { BounceLoader } from "react-spinners";

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];
const Form = ({ isAuth }) => {
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    reason: "",
    constraints: "",
    skills: "",
    details: "",
    deliverables: "",
    requiredDeliverables: "",
    budget: "",
    currency: "",
    email: "",
    createAt: "",
    priceSensitivity: "",
  });

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [isLoading, setIsLoading] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState([]);
  const [afterSubmit, setAfterSubmit] = useState(false);
  console.log("Errors",errorsMessage)

  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  if(afterSubmit){
    validationMessage()
  }
  };
  const handlefileChange = (e) => {
    setFormData({ ...formData, deliverables: e.target.files[0] });
  };
  const validationMessage = () => {
    let errorsObject = {};
    if (!(formData.title.length > 0)) {
      errorsObject.title =  "Please Enter Project Title";
    }
    if (!(formData.reason.length > 0)) {
      errorsObject.reason="Please fill out this field"
    }
    if (!(formData.constraints.length > 0)) {
      errorsObject.constraints="Please fill out this field"
    }
    if (!(formData.skills.length > 0)) {
      errorsObject.skills="Please fill out this field"
    }
    if (!(formData.email.length > 0)) {
      errorsObject.email="Please fill out this field"
    }
    if (!(formData.budget.length > 0)) {
      errorsObject.budget="Please fill out this field"
    }
  
    if (!(formData.details.length > 0)) {
      errorsObject.details="Please fill out this field"
    }
    if (!(formData.requiredDeliverables.length > 0)) {
      errorsObject.requiredDeliverables="Please fill out this field"
    }
    setErrorsMessage(errorsObject) ;

    return errorsObject
  };

  // const [title, setTitle] = useState();
  // const [reason, setReason] = useState();
  // const [constraints, setConstraints] = useState();
  // const [skills, setSkills] = useState();
  // const [details, setDetails] = useState();
  // const [deliverables, setDeliverables] = useState();
  // const [email, setEmail] = useState();

  // const handleSubmit = (e) => {
  //   console.group("Project Data");
  //   //console.log(`Project Title :${title} `);
  //   //console.log(`Why are you running this project?:${reason} `);
  //   //console.log(
  //     `What are the biggest constraints you are facing with this project?:${constraints} `
  //   );
  //   //console.log(`What are the skills required for your project?:${skills} `);
  //   //console.log(`The details:${details} `);
  //   //console.log(`Describe the expected deliverables :${deliverables} `);
  //   //console.log(`Contact email:${email} `);
  //   e.preventDefault();
  //   setTitle("");
  //   setConstraints("");
  //   setSkills("");
  //   setDetails("");
  //   setDeliverables("");
  //   setEmail("");
  // };

  const createProject = async (event) => {
    // if(!formData.title || formData.reason){
    //   alert("Please fill all Fields")
    //   return;
    // }

    // setAfterSubmit(true)

    // const errors = validationMessage();
    event.preventDefault();

    // if (Object.keys(errors).length> 0) {
    //   return;
    // } else {
      setIsLoading(true);

      const storageRef = ref(
        storage,
        `/images${Date.now()}${formData.deliverables.name}`
      );

      const uploadFile = uploadBytesResumable(
        storageRef,
        formData.deliverables
      );
      uploadFile.on(
        "state_changed",
        (snapshot) => {
          const progressPercent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progressPercent);
        },
        (err) => {
          //console.log(err)
        },
        () => {
          setFormData({
            title: "",
            reason: "",
            constraints: "",
            skills: "",
            details: "",
            deliverables: "",
            requiredDeliverables: "",
            email: "",
            createAt: "",
            budget: "",
            currency: "",
          });

          getDownloadURL(uploadFile.snapshot.ref).then((url) => {
            const projectsCollectionRef = collection(db, "Projects");

            try {
              addDoc(projectsCollectionRef, {
                title: formData.title,
                reason: formData.reason,
                constraints: formData.constraints,
                skills: formData.skills,
                details: formData.details,
                budget: formData.budget,
                currency: formData.currency,
                requiredDeliverables: formData.requiredDeliverables,
                deliverables: [url],
                email: formData.email,
                createAt: Timestamp.now().toDate(),
                startDate: startDate.$d,
                endDate: endDate.$d,
                priceSensitivity: formData.priceSensitivity,
                author: {
                  name: auth.currentUser.displayName,
                  id: auth.currentUser.uid,
                },
                status: "In Review",
                complete: false,
                deposite: false,
                fullPayment: false,
                comments: ["Your project is under review"],
                time: ` ${new Date().getDate()} | ${new Date().getMonth()}  - ${new Date().getHours()} :${new Date().getMinutes()} :${new Date().getSeconds()}`,
              })
                .then(setIsLoading(false))
                .then(
                  toast.success("The project is Submitted successfully", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  })
                );
            } catch (error) {
              //console.log(error)
              setIsLoading(false);
              toast.error("Error Occured , Please try again", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          });
        }
      );

      // navigate("/profile");
    // }
  };

  // useEffect(() => {

  //   if (!isAuth) {
  //     navigate('/login')
  //   }
  // }, [])

  return (
    <LoadingOverlay active={isLoading} spinner={<BounceLoader color="white" />}>
      <div className="form-section ">
        <div className="container">
          <form className="form" onSubmit={createProject}>
            <h1 className="form-heading">Submit A Project</h1>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            <Grid container justifyContent="center" spacing={5} rowSpacing={1}>
              <Grid item xs={12} md={6}>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { mb: 1, mt: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    helperText="Give a name that reflects the essence of your project"
                    id="demo-helper-text-misaligned"
                    label="Project Title"
                    name="title"
                    value={formData.title}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    error={errorsMessage?.title}
                  />
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Why are you running this project?"
                    name="reason"
                    value={formData.reason}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    error={errorsMessage?.reason}
                    helperText={errorsMessage?.reason ? "Please fill out this field ":""}

                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { mb: 1, mt: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="What are the biggest constraints you are facing with this project"
                    name="constraints"
                    value={formData.constraints}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    error={errorsMessage?.constraints}
                    helperText={errorsMessage?.constraints ? "Please fill out this field":""}

                  />
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="What are the skills required for your project"
                    name="skills"
                    value={formData.skills}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    error={errorsMessage?.skills}
                    helperText={errorsMessage?.skills ? "Please fill out this field":""}

                  />
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="Contact Email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    error={errorsMessage?.email}
                    helperText={errorsMessage?.email ? "Please enter your Email":""}

                  />
                  <Box>
                    {/* <Typography >What is the quality for price sensitivity for your project?
                    </Typography> */}
                    <Typography>What is your Budget ?</Typography>
                    {/* <Slider
                      aria-label="Temperature"
                      defaultValue={50}
                      // getAriaValueText={valuetext}
                      name="priceSensitivity"
                      onChange={(e) => handleChange(e)}
                      valueLabelDisplay='on'
                      step={10}
                      marks
                      min={10}
                      max={100}
                    /> */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        id="demo-helper-text-misaligned"
                        label="Budget"
                        name="budget"
                        value={formData.budget}
                        onChange={(e) => handleChange(e)}
                        type="number"
                        error={errorsMessage?.budget}
                        // helperText={errorsMessage?.budget ? "Please enter the budget":""}
    
                      />
                      <TextField
                        id="outlined-select-currency"
                        select
                        disabled
                        // label=" Currency"
                        defaultValue="USD"
                        name="currency"
                        value={"USD"}
                        onChange={(e) => handleChange(e)}
                        // error={errorsMessage?.currency}
                        // helperText={errorsMessage?.currency ? "Please enter the currency":""}

                      >
                        {currencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { mb: 1, mt: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-helperText"
                    label="Project Details "
                    name="details"
                    value={formData.details}
                    onChange={(e) => handleChange(e)}
                    multiline
                    rows={5.1}
                    fullWidth
                    error={errorsMessage?.details}
                    helperText={errorsMessage?.details ? "Please enter the project Details":""}
                  />
                </Box>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { mb: 1, mt: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    helperText="e.g. content, file, format"
                    id="demo-helper-text-misaligned"
                    label="Describe the expected deliverables "
                    name="deliverables"
                    value={formData.requiredDeliverables}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    error={errorsMessage?.requiredDeliverables}

                  />
                  {/* <TextField
                    helperText="e.g. content, file, format"
                    id="demo-helper-text-misaligned"
                    label="Upload any supporting documentation
                  "
                    name="title"
                    value={formData.title}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                  /> */}

                  <Button
                    variant="outlined"
                    sx={{ height: "55px" }}
                    fullWidth
                    component="label"
                    startIcon={<UploadIcon />}
                  >
                    <input
                      type="file"
                      name="deliverables"
                      // accept="image/*"
                      className="form-control"
                      onChange={(e) => handlefileChange(e)}
                      required

                    />
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "20px",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Start Date"
                        value={startDate}
                        minDate={dayjs("2017-01-01")}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                     
                        renderInput={(params) => <TextField  {...params} />}
                        

                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="End Date"
                        value={endDate}
                        minDate={dayjs("2017-01-01")}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Button type="submit" variant="contained">
              Submit Project
            </Button>
          </form>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Form;
