import React from 'react'
import homeIcon from "../../../images/homeIcon.png";
import projectsIcon from "../../../images/projectsIcon.png";
import paymentIcon from "../../../images/paymentIcon.png";
import settingsIcon from "../../../images/settingsIcon.png";
import './SideMenu.css'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../../contexts/AuthContext';



const SideMenu = () => {
  const { user } = UserAuth();

  const navigate = useNavigate()
  return (
      <div className="profile-data">
        <div className="profile-image">
           <img className='pp-image' src={user?.photoURL} alt="" /> 
          <p>{user?.displayName}</p>
        </div>
        <div className="profilsettingsIcone-tabs">
          {/* <div className="profile-tab">
            <img src={homeIcon} alt="" />
            <Button onClick={()=> navigate(`/`)}>Home</Button>
          </div> */}
          {/* <div className="profile-tab">
            <img src={projectsIcon} alt="" />
            <Button onClick={()=> navigate(`/profile`)}>Projects</Button>
          </div> */}
          {/* <div className="profile-tab">
            <img src={paymentIcon} alt="" />
            <span>Profile</span>
          </div>
          <div className="profile-tab">
            <img src={settingsIcon} alt="" />
            <span>Settings</span>
          </div> */}
        </div>
      </div>

  )
}

export default SideMenu