import { useContext, createContext, useState, useEffect } from "react";
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect, signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase-config";

const AuthContext = createContext();


export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({})
    const [isAdmin, setIsAdmin] = useState({})


    console.log('User',user)
    console.log('Display Name',user?.displayName)

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)

    }

    const logout = () => {
        signOut(auth)
    }

    useEffect(
        () => {
            const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
                setUser(currentUser)

                console.log("first",currentUser)
                currentUser.getIdTokenResult()
                    .then((idTokenResult) => {
                        //console.log(idTokenResult)
                        //  Confirm the user is an Admin.
                        if (idTokenResult.claims.admin) {
                            console.log("Admin User")
                            setIsAdmin(true)
                        } else {
                            //  Show regular user UI.
                            console.log("Client User ")
                            setIsAdmin(false)

                        }
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            })
            return () => {
                unsubscribe()
            }
        },
        [],
    )


    return (
        <AuthContext.Provider value={{ googleSignIn, logout, user , isAdmin }}>
            {children}
        </AuthContext.Provider>
    )
}


export const UserAuth = () => {
    return useContext(AuthContext)
}