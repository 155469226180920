import React, {  useMemo } from "react";
import useNews, { useUserProjects } from "../hooks/useProjects";
import  {useProjects} from "../hooks/useProjects";

const ProjectsContext = React.createContext();

export function ProjectsContextProvider({ children }) {
  const data = useProjects();
  return <ProjectsContext.Provider value={data}>{children}</ProjectsContext.Provider>;
}

export default ProjectsContext;
